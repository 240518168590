import styled, { css } from 'styled-components';

import { View } from '/imports/pdf/core/ui/atoms/View';

const ListItem = styled(View)`
  margin-top: ${({ theme: { convertPt } }) => convertPt(6)};
  position: relative;
  display: block;
  flex-direction: row;
  padding-left: ${({ length, bigPaddings, theme: { convertPx } }) =>
    bigPaddings
      ? length > 9
        ? convertPx(27, true)
        : convertPx(20, true)
      : length > 9
        ? convertPx(20, true)
        : convertPx(13, true)};
  align-items: center;
  page-break-inside: auto !important;
  break-inside: auto !important;
  ${(p) =>
    p.isRTL &&
    css`
      padding-right: ${({ length, bigPaddings, theme: { convertPx } }) =>
        bigPaddings
          ? length > 9
            ? convertPx(27, true)
            : convertPx(20, true)
          : length > 9
            ? convertPx(20, true)
            : convertPx(13, true)};
      padding-left: 0;
    `}
  ${(p) =>
    p.$noPadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
    
  ${(p) =>
    p.first &&
    css`
      margin-top: 0;
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      padding-right: ${({ length, bigPaddings, theme: { convertPx } }) =>
        bigPaddings
          ? length > 9
            ? convertPx(27, true)
            : convertPx(20, true)
          : length > 9
            ? convertPx(20, true)
            : convertPx(13, true)};
      padding-left: 0;
    `}
  ${({ $atsResume }) =>
    $atsResume &&
    css`
      padding-left: 0;
      margin-top: 0;
    `}

    ${({ $atsResume, theme: { isRTL, convertPx } }) => css`
    ${$atsResume &&
    isRTL &&
    css`
      padding-right: ${convertPx(5)};
    `}
  `}
    
      ${({ bruneiresume }) =>
    bruneiresume &&
    css`
      margin-top: 0;
    `}

    ${({ atsresume, theme: { convertPx, isRTL } }) =>
    atsresume &&
    isRTL &&
    css`
      padding-right: ${convertPx(11)};
    `}
`;

export default ListItem;
