import gql from 'graphql-tag';

import { allBlockFields, fullDetails } from '/imports/core/api/apollo/client/fragments';

export const resumeFull = gql`
  fragment ResumeFull on Resume {
    id
    createdAt
    updatedAt
    updatesCount
    dupesCount
    author
    name
    steps
    currentStep
    isOptimizedResume
    agentSearchId
    optimizedScoreAfter
    optimizedScoreBefore
    agentJobId
    details {
      ...FullDetails
    }
    settings {
      color
      template
      status
      language
      isLangUpdated
      isRetryAllowed
      isLangChecked
      contentFont
      headingFont
      arabicContentFont
      arabicHeadingFont
      fontSizeIncrementer
      templateVersion
    }
    blocks {
      id
      type
      title
      order
      atsOrder
      animationKey
      fixedOrder
      position
      fixedPosition
      animationKey
      required
      items {
        id
        order
        animationKey
        fields {
          ...AllBlockFields
        }
      }
      hideLevel
      showTagUI
      hideReferences
      isDOBFormatted
      required
      height
      importance
    }
    slug {
      slug
      template
      color
      sourceId
      isPrivate
    }
    hasWebsite
    fromLinkedIn
    fromScan
    showProfileInHTB
    generatedByOpenAI
    referenceResumeId
    saVersion
    isJobReviewed
    saDetails {
      isMarkFinished
      nameSize
      titleSize
      mainColumnSize
      generalFontSize
      paragraphSpacing
      sideBarContentLayout
      middleContentLayout
    }
  }
  ${allBlockFields}
  ${fullDetails}
`;

export const coverLetterFull = gql`
  fragment CoverLetterFull on CoverLetter {
    id
    createdAt
    updatedAt
    updatesCount
    dupesCount
    name
    coverLetterS3Link
    details {
      date
      userPic
      email
      firstName
      lastName
      phone
      userGender
      country
      city
      address
      postalCode
      jobTitle
      companyName
      companyAddress
      companyCity
      companyCountry
      companyPostalCode
      companyManagerName
      companyManagerEmail
      profileSummary
      letter
      showSignature
      signature
      signatureFont
      hiringManagerPosition
      isAICoverLetter
      gender
      salutation
      complimentaryclosing
    }
    settings {
      color
      template
      language
    }
    blocks {
      id
      type
      title
      order
      animationKey
      fixedOrder
      required
      items {
        id
        order
        animationKey
        fields {
          label
          url
        }
      }
      hideLevel
      hideReferences
    }
  }
`;
