import React from 'react';
import styled, { css } from 'styled-components';

import ListItem from '/imports/pdf/core/ui/atoms/ListItem';
import { View } from '/imports/pdf/core/ui/atoms/View';
import Flex from 'imports/core/ui/atoms/Flex';

const UnorderedListItem = ({
  children,
  BaseText,
  center,
  weight = 'default',
  isRTL,
  first,
  atsResume,
  bruneiresume,
}) => {
  const bulletStyle = {
    bold: '&#x25cf;',
    default: '&bull;',
    square: '&#9642;',
    none: '',
  };

  return (
    <ListItem
      isRTL={isRTL}
      $noPadding={weight === 'none'}
      first={first}
      $atsResume={atsResume}
      bruneiresume={bruneiresume}
    >
      {!center && (
        <Number isRTL={isRTL} className="bullet-point">
          <BaseText dangerouslySetInnerHTML={{ __html: bulletStyle[weight] }} />
        </Number>
      )}
      {atsResume ? (
        <Flex>
          <ATSBaseText dangerouslySetInnerHTML={{ __html: bulletStyle['square'] }} $atsResume={atsResume} />
          {children}
        </Flex>
      ) : (
        <BaseText>
          {center && `&bull;  `}
          {children}
        </BaseText>
      )}
    </ListItem>
  );
};

const ATSBaseText = styled.div`
  word-break: break-all;
  ${({ theme: { convertPx, isRTL } }) => css`
    font-size: ${convertPx(10)};
    line-height: ${convertPx(15)};
    margin-right: ${convertPx(5)};
    ${isRTL &&
    css`
      position: relative;
      margin-right: 0;
      left: ${convertPx(5)};
    `}
  `}
`;

const Number = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  ${(p) =>
    p.isRTL &&
    css`
      left: auto;
      right: 0;
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: auto;
      right: 0;
    `}
`;

export default UnorderedListItem;
