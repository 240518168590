import company_info from '../../../.cache/companyinfo/companyInfo.json';
import weekly_plan from '../../../.cache/specialplans/weekly.json';
// prettier-ignore
export const ERROR_MESSAGES = {
  required: 'error_required',
  email: 'error_email',
  numeric: 'error_numeric',
  phone: 'error_phone',
  zip: 'error_zip',
  chooseValue: 'error_choose_value',
  chooseRadio: 'error_choose_radio',
  chooseCheckbox: 'error_choose_checkbox',
  chooseAdultsOrKids: 'error_choose_adults_or_kids',
  jobRequired: 'error_job_required',
  employerRequired: 'error_employer_required',
  schoolRequired: 'error_school_required',
  requiredEmail: 'error_required_email',
  requiredJob: 'error_required_job',
  emailNotValid: 'error_email_not_valid',
  isEmailDuplicate: 'error_email_duplicate',
  emailNotFound: 'error_email_not_found',
  invalidUrl: 'error_invalid_url',
  isSlugDuplicate: 'duplicate_slug',
  emailPassowrdNotMatch: 'error_password_match',
  userNotHavePassword: 'error_user_not_have_password'
};

export const TEST_DATE = new Date(1995, 11, 17, 12, 0, 0);
export const WEEKLY_POOL = weekly_plan;

export const JOB_TRACKING_HOST = ['cvdeboss', 'modeles-cv'];
export const ACCPTED_CARDS_LOGO = [
  { img: 'cbV7.png', alt: 'CB' },
  { img: 'masterCardV7.png', alt: 'MasterCard' },
  { img: 'visaV7.png', alt: 'Visa' },
  { img: 'americanExpressV7.png', alt: 'American Express' },
];

export const COMPANY_INFO = company_info.companyInfo;

export const STORE_REDIRECT_HOST = 'resume-example';

export const FREE_CHECKOUT_DOMAIN = [
  { domain: 'elegantcv', country: ['BR', 'EG', 'IN', 'LK', 'PK', 'TH'] },
  { domain: 'cvdeboss', country: ['DZ', 'MG'] },
  { domain: 'resumedone', country: ['EG', 'IN', 'PK', 'TR'] },
  { domain: 'resumizeme', country: ['EG', 'IN', 'LK', 'PK', 'TR'] },
  { domain: 'bestonlineresume', country: ['IL', 'IN', 'LK', 'PK', 'TR'] },
  { domain: 'cvtoolspro', country: ['IN', 'PK', 'PS', 'TH'] },
  { domain: 'modeles-cv', country: ['MG', 'SN'] },
  { domain: 'cv-lite', country: ['PK', 'TZ', 'UG'] },
  { domain: 'resume-example', country: ['PK'] },
  { domain: 'theresumeranger', country: ['VN'] },
];

export const CHEKBOX_CANCEL_SUBSCRIPTION_ITEM = [
  { name: 'reason_1', value: 'contact_cancel_subscription_reason_1' },
  { name: 'reason_2', value: 'contact_cancel_subscription_reason_2' },
  { name: 'reason_3', value: 'contact_cancel_subscription_reason_3' },
  { name: 'reason_4', value: 'contact_cancel_subscription_reason_4' },
  { name: 'reason_5', value: 'contact_cancel_subscription_reason_5' },
];
