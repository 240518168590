import React from 'react';
import styled, { css } from 'styled-components';

import ListItem from '/imports/pdf/core/ui/atoms/ListItem.js';
import { View } from '/imports/pdf/core/ui/atoms/View';

const OrderedListItem = ({
  children,
  num,
  BaseText,
  center,
  length,
  isRTL,
  first,
  bigPaddings,
  className,
  isATSResume,
  bruneiresume,
  atsresume,
}) => {
  return (
    <ListItem
      bigPaddings={bigPaddings}
      length={length}
      first={first}
      className={className}
      bruneiresume={bruneiresume}
      atsresume={atsresume}
    >
      {!center && (
        <Number $isATSResume={isATSResume}>
          <BaseText isRTL={isRTL}>{num}.</BaseText>
        </Number>
      )}
      <BaseText isRTL={isRTL}>
        {center && `${num}.  `}
        {children}
      </BaseText>
    </ListItem>
  );
};

const Number = styled(View)`
  ${({ $isATSResume }) =>
    !$isATSResume &&
    css`
      left: 0;
      position: absolute;
      top: 0;
    `}
  ${({ $isATSResume }) =>
    $isATSResume &&
    css`
      width: fit-content;
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      right: 0;
    `}
`;

export default OrderedListItem;
