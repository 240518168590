import React from 'react';
import 'nprogress/nprogress.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'rc-slider/assets/index.css';
import absoluteURL from 'next-absolute-url';
import App from 'next/app';
import qs from 'qs';
import { ApolloProvider } from 'react-apollo';
import { experimentDebugger } from '@marvelapp/react-ab-test';
import nookies, { setCookie, destroyCookie } from 'nookies';
import { withRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider as PHProvider } from 'posthog-js/react';

import { cookieParser, removeExperiment, getDomainFromHost, getDefaultLanguage } from '/lib/helpers';
import env from '/env';
import Page from '/components/Page';
import { Push } from '/components/Link';
import withApolloClient from '/lib/withApolloClient';
import AxepioConsent from 'components/Axeptio';

const isDev = env.NODE_ENV === 'development';

if (env.NODE_ENV !== 'production') {
  experimentDebugger.setDebuggerAvailable(true);
  experimentDebugger.enable();
}

@withRouter
@withApolloClient
class MyApp extends App {
  static async getInitialProps({ ctx, Component }) {
    const { host } = absoluteURL(ctx.req);
    const referrerHost = ctx.req?.headers && ctx.req.headers['x-blog-referrer'];
    const refHost = ctx.req?.headers && ctx.req.headers.referer;
    const pageProps = Component.getInitialProps && (await Component.getInitialProps(ctx));
    return {
      pageProps: pageProps,
      refHost: refHost,
      host: getDomainFromHost(referrerHost || host),
      language: getDefaultLanguage(host),
      headers: ctx.req && ctx.req.headers,
    };
  }

  state = {
    host: null,
  };

  componentDidMount() {
    const { token, flag, agent_client_id } = cookieParser();
    const { router } = this.props;
    const url = new URLSearchParams(window.location.search);
    const eventUniqueId = url.get('eventUniqueId');
    const indicativeGG = nookies.get();
    const uniqueKey = indicativeGG[`Indicative_${env.INDICATIVE_KEY}`];
    const eventId = uniqueKey && JSON.parse(uniqueKey);
    const { hostname } = window.location;

    posthog.init(env.POSTHOG_KEY, {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'always',
      autocapture: 'false',
      loaded: (posthog) => {
        if (isDev) posthog.debug();
      },
    });
    if (eventId) {
      setCookie({}, 'indicative_unique_id', eventId.defaultUniqueID, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
        domain: isDev ? hostname : `.${hostname}`,
      });
    }
    if (eventUniqueId) {
      setCookie({}, 'indicative_unique_id', eventUniqueId, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
        domain: isDev ? hostname : `.${hostname}`,
      });
      setCookie({}, `FROM_URL_UNIQUEID`, eventUniqueId, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
        domain: isDev ? hostname : `.${hostname}`,
      });
    }

    this.setState({ host: getDomainFromHost(window.location.host) });
    const {
      router: {
        query: { isPrivate, language, token: tokenQuery },
      },
    } = this.props;
    if (tokenQuery) {
      setCookie({}, 'token', tokenQuery, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
        domain: isDev ? hostname : `.${hostname}`,
      });
    }
    if (isPrivate && !token) {
      if (tokenQuery) return;
      const host = getDomainFromHost(hostname);
      return Push('/', language || getDefaultLanguage(host));
    }

    removeExperiment('website_resume_experiment');

    if (window) {
      const originalFetch = window.fetch;
      window.fetch = async (url, options) => {
        try {
          const response = await originalFetch(url, options);
          return response;
        } catch (error) {
          window?.Rollbar && window.Rollbar?.error(error);
          throw error;
        }
      };
      const { search = '' } = window.location;
      const { origin = 'direct', campaign, gender, targetJob } = qs.parse(search.replace('?', ''));
      if (origin && !localStorage.getItem('resumedone:campaign-origin')) {
        localStorage.setItem('resumedone:campaign-origin', origin);
      }
      if (campaign) {
        localStorage.setItem('resumedone:campaign-campaign', campaign);
      }
      if (gender) {
        // NOTE: store gender in reverse order
        let campaignGenderValue;
        if (Array.isArray(gender)) {
          campaignGenderValue = gender[0];
        } else {
          campaignGenderValue = gender;
        }
        localStorage.setItem('resumedone:campaign-redneg', campaignGenderValue.split('').reverse().join(''));
      }
      if (targetJob) {
        localStorage.setItem('resumedone:campaign-targetJob', targetJob);
      }
    }
    if (router.query?.agentClientUserId) {
      const agentClientUserId = router.query.agentClientUserId;
      const agentClientUserName = router.query.agentClientUserName;
      if (agent_client_id) {
        if (agent_client_id !== agentClientUserId) {
          destroyCookie(null, 'agent_client_id', {
            path: '/',
          });
          setTimeout(() => {
            setCookie(null, 'agent_client_id', agentClientUserId, {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            });
          }, 100);
          if (agentClientUserName !== undefined) {
            setCookie(null, 'agent_client_name', agentClientUserName, {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            });
          }
          window.location.reload();
          return;
        }
      } else {
        setCookie(null, 'agent_client_id', agentClientUserId, {
          maxAge: 30 * 24 * 60 * 60,
          path: '/',
          domain: isDev ? hostname : `.${hostname}`,
        });
      }
      if (agentClientUserName !== undefined) {
        setCookie(null, 'agent_client_name', agentClientUserName, {
          maxAge: 30 * 24 * 60 * 60,
          path: '/',
        });
      }
      return;
    }
  }

  render() {
    const { Component, apolloClient, host, language, refHost } = this.props;

    return (
      <ApolloProvider client={apolloClient}>
        <PHProvider client={posthog}>
          <>
            <AxepioConsent host={host} />
            <Page host={this.state.host || host} language={language} refHost={refHost}>
              <Component {...this.props} />
            </Page>
          </>
        </PHProvider>
      </ApolloProvider>
    );
  }
}

export default MyApp;
