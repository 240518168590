import _ from 'lodash';
import moment from 'moment';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { v4 as uuidV4 } from 'uuid';

import Meta from '/components/Meta';
import useIntl from '/imports/core/api/useIntl';
import { ToasterProvider } from '/imports/core/api/useToaster';
import useTracking from '/imports/core/hooks/useTracking';
import Toaster from '/imports/core/ui/components/Toaster';
import { RTLLanguages } from '/imports/generator/api/constants';
import { userActivity } from '/imports/job-tracking/api/api';
import { isJobTrackEnable, removeExperiment, websiteFontLoad } from '/lib/helpers';
import GlobalStyle from '/styles/general';
import Theme from '/styles/theme';

const StyledPage = styled.section`
  color: black;
`;

const PageRender = ({ host, route, children }) => {
  const setJobTrackTheme = route === '/login';
  const { locale, t } = useIntl();
  const { getTrackEventData } = useTracking();
  const { token } = nookies.get({});
  const { trackEvent } = useTracking();

  useEffect(() => {
    const removeExp = async () => removeExperiment('experiment_resume_nation_global');

    removeExp();
  }, []);
  const callbackBeforeUnload = () => {
    const indicativeObj = getTrackEventData('browser_tab_close_or_reload');
    if (!_.isEmpty(indicativeObj)) {
      navigator.sendBeacon('/api/indicative', JSON.stringify(indicativeObj));
    }
  };
  const isHtmlPreview = route === '/resume-html';
  useEffect(() => {
    window.addEventListener('beforeunload', callbackBeforeUnload);
    return () => {
      return window.removeEventListener('beforeunload', callbackBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const userAction = async () => {
      if (token && isJobTrackEnable()) {
        userActivity(token);
      }
    };

    userAction();
  }, []);

  useEffect(() => {
    if (!token) localStorage.setItem('guestId', uuidV4());
  }, [token]);

  useEffect(() => {
    const lastActionDate = localStorage.getItem('DATE_LAST_ACTION');
    const email = localStorage.getItem('email');
    if (email) {
      if (!lastActionDate) {
        localStorage.setItem('DATE_LAST_ACTION', moment().format('DD-MM-YYYY'));
        if (typeof window !== 'undefined' && window.sendinblue?.identify && email) {
          window.sendinblue.identify(email, { DATE_LAST_ACTION: moment().format('DD-MM-YYYY') });
        }
        trackEvent('DATE_LAST_ACTION', moment().format('DD-MM-YYYY'));
      } else {
        if (moment().diff(lastActionDate, 'days') >= 1) {
          if (typeof window !== 'undefined' && window.sendinblue?.identify && email) {
            window.sendinblue.identify(email, { DATE_LAST_ACTION: lastActionDate });
          }
          trackEvent('DATE_LAST_ACTION', moment().format('DD-MM-YYYY'));
        } else {
          localStorage.setItem('DATE_LAST_ACTION', moment().format('DD-MM-YYYY'));
        }
      }
    }
  }, []);

  return (
    <ThemeProvider
      theme={{
        ...Theme,
        font: { ...Theme.font, family: { ...Theme.font.family, ...websiteFontLoad(locale) } },
        isRTL: RTLLanguages.includes(locale),
        ...(setJobTrackTheme && { isJobTrack: isJobTrackEnable() }),
      }}
    >
      <ToasterProvider>
        <Fragment>
          {!isHtmlPreview && <GlobalStyle />}
          {!isHtmlPreview && <Meta domain={host} />}
          <StyledPage>{children}</StyledPage>
          <Toaster />
        </Fragment>
      </ToasterProvider>
    </ThemeProvider>
  );
};

PageRender.propTypes = {
  host: PropTypes.string,
  route: PropTypes.string,
  children: PropTypes.node,
};

export default PageRender;
