import React, { useContext } from 'react';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import nookies from 'nookies';
import publicIp from 'public-ip';
import qs from 'qs';
import { getCountry, getTimezone } from 'imports/checkout/api/utils';
import { getEmailProvider } from '../ui/helpers';
import { useRouter } from 'next/router';
import env from '/env';
import posthog from 'posthog-js';
import useWindowSize from '/imports/core/api/useWindowSize';

import AccountContext from '/imports/core/api/accounts/accountContext';
import { countryGroup } from '/imports/core/hooks/countryGroup';
import { getRdtID, isPaidUser, trackIdentitySendinBlue } from '/lib/helpers';
import useIntl from '/imports/core/api/useIntl';
import { useMixpanel } from '/imports/core/api/useMixpanel';
import { useResponsive } from '/imports/core/api/responsiveContext';
let userIP;
const setUserIp = async () => {
  if (userIP) return;
  try {
    userIP = await publicIp.v4();
  } catch (e) {
    console.log('failed to get user IP');
  }
};
// self invoking(closure) function to set user's IP address on load
(() => {
  setUserIp();
})();

export const categorizeResolution = (width) => {
  const thresholds = {
    very_low: 1024,
    low: 1280,
    medium_low: 1366,
    medium: 1600,
    medium_high: 1920,
    high: 2560,
  };
  if (width <= thresholds.very_low) {
    return 'very_low';
  } else if (width <= thresholds.low) {
    return 'low';
  } else if (width <= thresholds.medium_low) {
    return 'medium_low';
  } else if (width <= thresholds.medium) {
    return 'medium';
  } else if (width <= thresholds.medium_high) {
    return 'medium_high';
  } else if (width <= thresholds.high) {
    return 'high';
  } else {
    return 'very_high';
  }
};

const useTracking = () => {
  const { host, deviceInfo, screenResolution } = useResponsive();
  const userInfo = useContext(AccountContext);
  const { locale } = useIntl();
  const { track, reset, identify, alias, people } = useMixpanel();
  const { asPath } = useRouter();
  const cookie_data = nookies.get();
  const { width: windowWidth } = useWindowSize();
  /**
   * Checks if this route is the first one the user
   * lands on visiting the platform. It sends an event
   * to Mixpanel to help marketing tracking the result
   * of campaigns and cross check conversions.
   */
  const sessionStartHandler = () => {
    const sessionStarted = sessionStorage.getItem('session_started_check');

    if (sessionStarted) return;
    try {
      trackEvent('session_started', { first_session_started: true });
      // trackEvent('utm_source_jaril', { utm_source_jaril: refHost });
    } catch (error) {
      console.log('Error', error);
    }

    return sessionStorage.setItem('session_started_check', true);
  };

  const getCountryCluster = (code) => {
    const country = countryGroup.find((country) => country?.Code === code);
    return country?.GoupId || 2;
  };

  const getInitialProperties = (eventName = '') => {
    const country = getCountry();
    const timezone = getTimezone();
    const parsedValue = qs.parse(asPath);
    let origin = 'direct';
    Object.entries(parsedValue).forEach(([key, value]) => {
      if (key.includes('origin')) {
        origin = value;
      }
    });
    const { width, height } = screenResolution;
    let obj = {
      origin,
      screenResolution: `${width}x${height}`,
      resolution_group: categorizeResolution(windowWidth),
      country_group: getCountryCluster(country),
      current_viewport: `${windowWidth}px`,
      website_language: locale,
      deviceType: deviceInfo.type,
      deviceOS: deviceInfo.OS,
      deviceBrand: deviceInfo.brand,
      deviceModel: deviceInfo.model,
      host,
      country,
      timezone,
    };
    if (!!eventName) {
      obj[`${eventName}_time`] = new Date().toISOString();
    }
    return obj;
  };

  const setPeople = (properties = {}) => {
    people.set({ ...properties, ...getInitialProperties() });
  };

  const trackAlias = (id) => {
    alias(id);
  };

  const trackUserIdentity = async (
    { id, firstName, lastName, initialDevice, email, createdAt, role, primaryLanguage, gender },
    passEmail = true,
    extraParams = {},
    isRegister = false,
  ) => {
    const isProd = env.NODE_ENV === 'production';
    const country = getCountry();
    let trackedUser;
    // NOTE: Just Verify if user's IP address is not set then set it.
    if (!userIP) {
      await setUserIp();
    }
    let params = { ...extraParams };
    if (isRegister) {
      params.firstIP = userIP;
    } else {
      params.lastIP = userIP;
    }
    if (passEmail) {
      params = { ...params, email, email_provider: getEmailProvider(email) };
    }
    const variant = marvelEmitter.getActiveVariant('website_resume_experiment');
    if (variant) {
      params.website_resume_experiment = variant;
    }
    const origin = localStorage.getItem('resumedone:campaign-origin');
    const campaign = localStorage.getItem('resumedone:campaign-campaign');
    const targetJob = localStorage.getItem('resumedone:campaign-targetJob');

    if (origin != null) params.origin = origin;
    if (campaign != null) params.campaign = campaign;
    if (targetJob != null) params.targetJob = targetJob;

    if (gender) params.gender = gender;
    params.initialDevice = initialDevice;
    if (primaryLanguage) {
      params.primaryLanguage = primaryLanguage;
    }
    params.domain = host;

    // Track user identity on customer.io
    if (id && email) {
      const trackParams = { ...params, email, email_provider: getEmailProvider(email) };
      if (firstName) trackParams.firstName = firstName;
      if (lastName) trackParams.lastName = lastName;

      if (typeof window !== 'undefined' && window?.rdt) {
        const rdtId = getRdtID(host);
        window.rdt('init', rdtId, {
          email: email,
          externalId: id,
        });
      }
      posthog.identify(id, trackParams);
      // NOTE: customer.id requires created_at param name so replace it
      delete trackParams.createdAt;
      if (createdAt) {
        trackParams.created_at = Math.round(new Date(createdAt).getTime() / 1000);
      }
      if (role) {
        trackParams.is_subscribed = isPaidUser(role);
      }
      if (window && window.fbq) {
        window.fbq('init', '1360767618174894', {
          em: email,
          fn: firstName,
          ln: lastName,
        });
      }
      if (window && window?.gtag) {
        let userEmail = email;
        if (
          window?.axeptioSDK &&
          (window.axeptioSDK?.userPreferencesManager?.choices?.$$googleConsentMode?.ad_storage === 'denied' ||
            window.axeptioSDK?.userPreferencesManager?.choices?.$$googleConsentMode?.ad_user_data === 'denied')
        ) {
          userEmail = await hashString(email);
        }
        window.gtag('set', 'user_data', { email: userEmail });
      }
      if (typeof window !== 'undefined' && window.sendinblue?.identify) {
        window.sendinblue.identify(email);
      }
      if (typeof window !== 'undefined' && window?.hj) {
        window.hj('identify', email, { ...trackParams });
      }
      if (typeof window !== 'undefined' && window?.ttq) {
        window.ttq.identify('identify', email, { ...trackParams });
      }
      trackedUser = await trackIdentitySendinBlue({ email: id, ...trackParams });
    }
    setPeople(params);
    return trackedUser;
  };

  const getTrackEventData = (eventName, properties = {}) => {
    const { FROM_URL_UNIQUEID } = cookie_data;
    const url = new URLSearchParams(window.location.search);
    const eventid = url.get('eventUniqueId') || FROM_URL_UNIQUEID;
    if (typeof window !== 'undefined') {
      const indicativeObj = {
        ...getInitialProperties(eventName),
        ...properties,
        eventName: eventName,
        eventUniqueId: eventid,
        url_route: window.location.pathname,
      };
      if (userInfo?.currentUser?.id) {
        indicativeObj.userId = userInfo.currentUser.id;
        indicativeObj.email = userInfo.currentUser.email;
        indicativeObj.gender = userInfo.currentUser.gender;
      }
      return indicativeObj;
    }
  };

  const trackEvent = (eventName, properties = {}, sendingblue = false) => {
    const isProd = env.NODE_ENV === 'production';
    if (!isProd) {
      console.log(eventName, properties);
    }

    const { FROM_URL_UNIQUEID } = cookie_data;
    const url = new URLSearchParams(window.location.search);
    const eventid = url.get('eventUniqueId') || FROM_URL_UNIQUEID;
    let posthogProperties = {
      ...getInitialProperties(eventName),
      ...properties,
    };
    if (userInfo?.currentUser?.id) {
      posthogProperties = {
        ...posthogProperties,
        userId: userInfo.currentUser.id,
        email: userInfo.currentUser.email,
        gender: userInfo.currentUser.gender,
      };
      posthogProperties.$set = {
        ...posthogProperties,
        userId: userInfo.currentUser.id,
        email: userInfo.currentUser.email,
        gender: userInfo.currentUser.gender,
      };
    }
    if (typeof window !== 'undefined' && window.Indicative) {
      const indicativeObj = {
        ...getInitialProperties(eventName),
        ...properties,
      };
      if (userInfo?.currentUser?.id && window?.axeptioSDK?.userPreferencesManager?.choices?.Indicative !== false) {
        indicativeObj.userId = userInfo.currentUser.id;
        indicativeObj.email = userInfo.currentUser.email;
        indicativeObj.gender = userInfo.currentUser.gender;
      }
      if (!!eventid) window?.Indicative?.buildEvent(eventName, eventid, indicativeObj);
      else if (userInfo?.currentUser?.id) {
        const eventUniqueId = userInfo.currentUser.id;
        window?.Indicative?.buildEvent(eventName, eventUniqueId, indicativeObj);
      } else window?.Indicative?.buildEvent(eventName, indicativeObj);
    }
    posthog.capture(eventName, posthogProperties);
    track(eventName, { ...getInitialProperties(eventName), ...properties });
    if (userInfo?.currentUser?.email && typeof window !== 'undefined' && window.sendinblue?.track && sendingblue) {
      window.sendinblue.track(eventName, { ...getInitialProperties(eventName), ...properties });
    }
    if (typeof window !== 'undefined' && window?.hj) {
      window.hj('event', eventName);
    }
  };

  const resetTracking = () => {
    reset();
  };

  const identifyTracking = () => {
    identify();
  };

  return {
    getTrackEventData,
    trackUserIdentity,
    trackEvent,
    resetTracking,
    sessionStartHandler,
    setPeople,
    trackAlias,
    identify: identifyTracking,
  };
};

export async function hashString(str) {
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
}

export const withTracking = (Component) => {
  function Wrapper(props) {
    const tracking = useTracking();
    return <Component {...tracking} {...props} />;
  }

  Wrapper.getInitialProps = async (ctx) => {
    const pageProps = Component.getInitialProps && (await Component.getInitialProps(ctx));
    return { ...pageProps };
  };

  return Wrapper;
};

export default useTracking;
