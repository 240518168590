import styled, { css } from 'styled-components';

// Avoids
export const UnbreakableView = styled.div`
  display: block;
  break-inside: avoid;
  page-break-inside: avoid;

  ${(p) =>
    p.isBreakable &&
    css`
      break-inside: auto;
      page-break-inside: auto;
    `}

  ${({ theme: { convertPx }, $atsresume, nomargin }) => css`
    ${$atsresume &&
    css`
      margin-top: ${convertPx(4)};
    `}
    ${nomargin &&
    css`
      margin-top: 0;
    `}
  `}
`;

export default UnbreakableView;
