import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import useToaster from '/imports/core/api/useToaster';

const Toaster = () => {
  const { open, info } = useToaster();
  const ref = useRef();

  return (
    <CSSTransition appear in={open} unmountOnExit classNames="toast" timeout={500}>
      <ToastWrapper>
        <Toast ref={ref} isError={info.error}>
          <ToastText>{info.text}</ToastText>
        </Toast>
      </ToastWrapper>
    </CSSTransition>
  );
};

const ToastWrapper = styled.div`
  &.toast-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  &.toast-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:
      opacity 500ms,
      transform 500ms;
  }
  &.toast-exit {
    opacity: 1;
  }
  &.toast-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition:
      opacity 500ms,
      transform 500ms;
  }
  position: fixed;
  width: 100%;
  top: 10px;
  z-index: 99999;
`;

const Toast = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  min-height: 50px;
  min-width: 250px;
  border-radius: 6px;
  box-shadow: 2px 2px 10px #ccc;
  user-select: none;
  z-index: 9999;
  color: #fff;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  width: max-content;
  margin: 0 auto;
  background-color: #0087c1;
  ${({ isError }) =>
    isError &&
    css`
      background-color: red;
    `}
`;

const ToastText = styled.div``;

export default Toaster;
