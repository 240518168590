import { useEffect } from 'react';
import publicIp from 'public-ip';
import { axeptioSettingsPerDomain, getLocation } from 'lib/helpers';

const init = () => {
  void 0 === window._axcb && (window._axcb = []);
  window.uetq = window.uetq || [];
  window._axcb.push(function (axeptio) {
    axeptio.on('cookies:complete', function (axeptio) {
      if (axeptio?.['$$googleConsentMode']?.ad_storage === 'granted') {
        window.uetq.push('consent', 'update', {
          ad_storage: 'granted',
        });
      } else if (axeptio?.['$$googleConsentMode']?.ad_storage === 'denied') {
        window.uetq.push('consent', 'update', {
          ad_storage: 'denied',
        });
      }
    });
  });
};

export default function AxepioConsent({ host }) {
  useEffect(() => {
    const fetchCountryCodeByIp = async () => {
      try {
        const IP = await publicIp.v4();
        const countryCode = await getLocation(IP || '127.0.0.1');
        if (!isInCountryToDisplay(countryCode)) return;
        const isAxeptioNotActive = !axeptioSettingsPerDomain?.[host] || axeptioSettingsPerDomain?.[host]?.trim() === '';
        if (isAxeptioNotActive) return;
        window.axeptioDefaultChoices = {
          Bing: true,
          Indicative: true,
          bing: true,
          facebook_pixel: true,
          microsoft: true,
        };
        window.axeptioSettings = {
          clientId: axeptioSettingsPerDomain?.[host],
          cookiesVersion: `${host}-cookies`,
          googleConsentMode: {
            default: {
              analytics_storage: 'granted',
              ad_storage: 'granted',
              ad_user_data: 'granted',
              ad_personalization: 'granted',
              wait_for_update: 500,
            },
          },
        };

        (function (d, s) {
          var t = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          e.async = true;
          e.src = '//static.axept.io/sdk-slim.js';
          t.parentNode.insertBefore(e, t);
        })(document, 'script');
        init();
      } catch (e) {
        console.error('Error while fetching ip country code:', e);
        return null;
      }
    };
    fetchCountryCodeByIp();
  }, []);

  return null;
}

function isInCountryToDisplay(code = '') {
  const countryCodes = [
    // UK
    'GB', // United Kingdom

    // EEA Countries
    'IS', // Iceland (Europe/Reykjavik)
    'LI', // Liechtenstein (Europe/Vaduz)
    'NO', // Norway (Europe/Oslo)

    // EU Countries (EEA countries as well)
    'AT', // Austria (Europe/Vienna)
    'BE', // Belgium (Europe/Brussels)
    'BG', // Bulgaria (Europe/Sofia)
    'HR', // Croatia (Europe/Zagreb)
    'CY', // Cyprus (Asia/Nicosia)
    'CZ', // Czech Republic (Europe/Prague)
    'DK', // Denmark (Europe/Copenhagen)
    'EE', // Estonia (Europe/Tallinn)
    'FI', // Finland (Europe/Helsinki)
    'FR', // France (Europe/Paris)
    'DE', // Germany (Europe/Berlin, Europe/Busingen)
    'GR', // Greece (Europe/Athens)
    'HU', // Hungary (Europe/Budapest)
    'IE', // Ireland (Europe/Dublin)
    'IT', // Italy (Europe/Rome)
    'LV', // Latvia (Europe/Riga)
    'LT', // Lithuania (Europe/Vilnius)
    'LU', // Luxembourg (Europe/Luxembourg)
    'MT', // Malta (Europe/Malta)
    'NL', // Netherlands (Europe/Amsterdam)
    'PL', // Poland (Europe/Warsaw)
    'PT', // Portugal (Europe/Lisbon, Atlantic/Madeira, Atlantic/Azores)
    'RO', // Romania (Europe/Bucharest)
    'SK', // Slovakia (Europe/Bratislava)
    'SI', // Slovenia (Europe/Ljubljana)
    'ES', // Spain (Europe/Madrid, Africa/Ceuta, Atlantic/Canary)
    'SE', // Sweden (Europe/Stockholm)

    // Switzerland (Not in EEA but part of the EU-Switzerland agreements)
    'CH', // Switzerland (Europe/Bern)

    'US', // United States (America/Los_Angeles)
  ];

  if (countryCodes.includes(code)) {
    return true;
  } else {
    return false;
  }
}
