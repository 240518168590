import ct from 'countries-and-timezones';
import moment from 'moment';

import { LANGUAGES_CODES, supported } from 'imports/generator/api/constants';
import BoardIcon from '/imports/job-tracking/ui/assets/LayoutBoardIcon';
import LayoutSearchIcon from '/imports/job-tracking/ui/assets/LayoutSearchIcon';
import TemplateIcon from '/imports/job-tracking/ui/assets/LayoutTemplateIcon';
import WishlistHeartIcon from '/imports/job-tracking/ui/assets/WishlistHeartIcon';
import env from '/env';
import { JOB_TRACKING_HOST } from 'imports/core/api/constants';

export const StepProgressList = [
  'Wishlist',
  'Applying',
  'Applied',
  'Interview',
  'Offer',
  'Offer Accepted',
  'Offer Rejected',
];
export const LinkConfig = [
  {
    as: '/app/dashboard',
    href: '/app?template=dashboard',
    active: 'dashboard',
    title: 'My templates',
    logo: () => <TemplateIcon />,
    slug: 'jobtrack_sidebar_mytemplates_text',
  },
  {
    as: '/app/search',
    href: '/app?template=search',
    active: 'search',
    title: 'Searches',
    logo: () => <LayoutSearchIcon />,
    isFeature: true,
    slug: 'jobtrack_sidebar_searches_text',
  },

  {
    as: '/app/saved-jobs',
    href: '/app?template=saved-jobs',
    active: 'saved-jobs',
    title: 'Saved Jobs',
    logo: () => <WishlistHeartIcon isColored />,
    isFeature: true,
    slug: 'jobtrack_sidebar_saved_jobs_text',
  },
  {
    as: '/app/polish-resumes',
    href: '/app?template=polish-resumes',
    active: 'polish-resumes',
    title: 'Polish Resumes',
    isFeature: true,
    logo: () => <TemplateIcon />,
    slug: 'jobtrack_sidebar_polish_resumes_text',
  },
  {
    as: '/app/resumes-tracking',
    href: '/app?template=resumes-tracking',
    active: 'resumes-tracking',
    title: 'Resumes Tracking',
    isFeature: true,
    logo: () => <TemplateIcon />,
    slug: 'jobtrack_sidebar_resume_tracking_text',
  },
  {
    as: '/app/search-v2',
    href: '/app?template=search-v2',
    active: 'search-v2',
    title: 'Searches V2',
    logo: () => <LayoutSearchIcon />,
    isFeature: true,
    slug: 'jobtrack_sidebar_searches_text',
  },
  {
    as: '/app/prompt-testing',
    href: '/app?template=prompt-testing',
    active: 'prompt-testing',
    title: 'Prompt Testing',
    isFeature: true,
    logo: () => <TemplateIcon />,
    slug: 'jobtrack_sidebar_prompt_testing_text',
  },
  // {
  //   href: '/app/calendar',
  //   as: '/app/calendar',
  //   active: 'calendar',
  //   title: 'Calendar',
  //   logo: () => <CalendarIcon />,
  //   isFeature: true,
  // },
  {
    as: '/app/tracking',
    href: '/app?template=tracking',
    active: 'tracking',
    title: 'My boards',
    logo: () => <BoardIcon />,
    isFeature: true,
    slug: 'jobtrack_sidebar_myboards_text',
  },
];

export const CHIP_LIST = [
  { id: 1, background: 'var(--chip-purple)' },
  { id: 2, background: 'var(--chip-blue)' },
  { id: 3, background: 'var(--chip-yellow)' },
  { id: 4, background: 'var(--chip-gray)' },
  { id: 5, background: 'var(--chip-green)' },
];

export const TAGS_LIST = [
  { text: 'Catering', code: 0 },
  { text: 'Law', code: 1 },
  { text: 'Factory', code: 2 },
  { text: 'Automotive', code: 3 },
  { text: 'Retail', code: 4 },
  { text: 'HR', code: 0 },
  { text: 'Marketing', code: 1 },
  { text: 'Technician', code: 2 },
  { text: 'Sales', code: 3 },
  { text: 'Administration', code: 4 },
  { text: 'Real Estate', code: 0 },
  { text: 'Business', code: 1 },
  { text: 'Other', code: 2 },
  { text: 'IT', code: 3 },
  { text: 'Reception', code: 4 },
  { text: 'Insurance', code: 0 },
  { text: 'Engineering', code: 1 },
  { text: 'Management', code: 2 },
  { text: 'Logistics', code: 3 },
  { text: 'Finance', code: 4 },
  { text: 'Accountancy', code: 0 },
  { text: 'Hospitality', code: 1 },
  { text: 'Customer Service', code: 2 },
  { text: 'Assistant/Secretarial', code: 3 },
  { text: 'Health', code: 4 },
  { text: 'Banking', code: 0 },
  { text: 'Tourism/Leisure', code: 1 },
  { text: 'Construction', code: 2 },
  { text: 'Security/Safety', code: 3 },
  { text: 'Media/Digital/Creative', code: 4 },
  { text: 'Education', code: 0 },
  { text: 'Journalism', code: 1 },
  { text: 'Others', code: 2 },
  { text: 'Driver', code: 3 },
  { text: 'Aviation', code: 4 },
];

export const KEYWORD = [
  { name: 'Health Care', id: 1 },
  { name: 'Software', id: 2 },
  { name: 'Financial Services', id: 3 },
  { name: 'Information Tecnology', id: 4 },
  { name: 'Cyber Security', id: 5 },
  { name: 'Lifestyle', id: 6 },
  { name: 'Professional Services', id: 7 },
];

export const stringToNull = (myObj) => {
  Object.keys(myObj).forEach((k) => {
    myObj[k] = myObj[k] === '' ? null : myObj[k];
  });
  return myObj;
};

export const JobTrackingSteps = ['Wishlist', 'Applied', 'Interview', 'Offer'];

export const SUGGSTION_TAGS = [
  {
    name: 'Date Post',
    value: [
      { value: 'Last 24 hours', slug: 'jobtrack_date_filter_options_1' },
      { value: 'Last 3 days', slug: 'jobtrack_date_filter_options_2' },
      { value: 'Last 7 days', slug: 'jobtrack_date_filter_options_3' },
      { value: 'Last 14 days', slug: 'jobtrack_date_filter_options_4' },
    ],
    id: 'datePost',
    slug: 'jobtrack_datepost_filter_text',
  },
  {
    name: 'Remote',
    value: [
      { value: 'Remote', slug: 'remote_filter_options_1' },
      { value: 'On Site', slug: 'remote_filter_options_2' },
      { value: 'All Jobs', slug: 'remote_filter_options_3' },
    ],
    id: 'remote',
    slug: 'jobtrack_remote_filter_text',
  },
  {
    name: 'Education level',
    value: [
      { value: "Bachelor's degree", slug: 'jobtrack_education_filter_options_1' },
      { value: '12th Pass', slug: 'jobtrack_education_filter_options_2' },
      { value: "Master's degree", slug: 'jobtrack_education_filter_options_3' },
      { value: 'Diploma', slug: 'jobtrack_education_filter_options_4' },
      { value: '10th Pass', slug: 'jobtrack_education_filter_options_5' },
      { value: 'Doctoral degree', slug: 'jobtrack_education_filter_options_6' },
    ],
    id: 'educationLevel',
    slug: 'jobtrack_education_filter_text',
  },
  {
    name: 'Salary Estimate',
    value: ['All Jobs', '$ 12,500+/month', '$ 15,000+/month', '$ 17,500+/month', '$ 20,000+/month', '$ 25,000+/month'],
    id: 'salary',
    slug: 'jobtrack_salary_filter_text',
  },
  {
    name: 'Job Type',
    value: ['All Jobs', 'Full-time', 'Fresher', 'Part-time', 'Internship', 'Contract', 'Temporary'],
    id: 'jobType',
    slug: 'jobtrack_jobtype_filter_text',
  },
  {
    name: 'Skills',
    value: [
      { value: 'Computer skills', slug: 'jobtrack_salary_filter_options_1' },
      { value: 'Time management', slug: 'jobtrack_salary_filter_options_2' },
      { value: 'Customer Service', slug: 'jobtrack_salary_filter_options_3' },
      { value: 'Data entry', slug: 'jobtrack_salary_filter_options_4' },
      { value: 'Organizational Skills', slug: 'jobtrack_salary_filter_options_5' },
      { value: 'Typing', slug: 'jobtrack_salary_filter_options_6' },
      { value: 'Computer Literacy', slug: 'jobtrack_salary_filter_options_7' },
      { value: 'Word Processing', slug: 'jobtrack_salary_filter_options_8' },
      { value: 'Phone Etiquette', slug: 'jobtrack_salary_filter_options_9' },
    ],
    id: 'skills',
    slug: 'jobtrack_skills_filter_text',
  },
  { name: 'Location', value: ['All Jobs', 'Local Jobs'], id: 'location', slug: 'jobtrack_location_filter_text' },
  { name: 'Company', value: ['All Jobs'], id: 'company', slug: 'jobtrack_company_filter_text' },
  {
    name: 'Jobs Provider',
    id: 'partner',
    value: [
      { value: 'Adzuna', slug: 'jobtrack_jobprovider_filter_options_1' },
      { value: 'Jobadx', slug: 'jobtrack_jobprovider_filter_options_2' },
      { value: 'Jobg8', slug: 'jobtrack_jobprovider_filter_options_3' },
      { value: 'Google', slug: 'jobtrack_jobprovider_filter_options_4' },
    ],
    slug: 'jobtrack_jobsprovider_filter_text',
  },
  {
    name: 'Contract Type',
    value: ['CDI', 'CDD', 'Apprentissage', 'Stage', 'Saisonnier', 'Intérim', 'Contrat Pro'],
    id: 'contractType',
    slug: 'jobtrack_contractType_filter',
  },
  { name: 'Check Needed', value: [true, false], id: 'isManualCheck' },
];

export const AGENT_SUGGESTION_TAGS = [
  {
    name: 'Api provider',
    value: [
      // { value: 'search-v2', name: 'V1', slug: 'api_provider_v2' },
      // { value: 'search-v3', name: 'V2', slug: 'api_provider_v3' },
      // { value: 'search-v4', name: 'V3', slug: 'api_provider_v4' },
      // { value: 'search-v5', name: 'V4', slug: 'api_provider_v5' },
      { value: 'search-v6', name: 'V5', slug: 'api_provider_v6' },
    ],
    id: 'apiProvider',
    slug: 'job_track_filter_api_provider',
  },
  {
    name: 'Date Post',
    value: [
      { value: 'today', name: 'Today', slug: 'jobtrack_deadline_today_text' },
      { value: '3days', name: 'Last 3 days', slug: 'jobtrack_date_filter_options_2' },
      { value: 'week', name: 'Last week', slug: 'weeks' },
      { value: 'month', name: 'Last month', slug: 'month' },
    ],
    id: 'datePost',
    slug: 'jobtrack_datepost_filter_text',
  },
  {
    name: 'Easy Apply',
    value: [
      { value: 'Yes', slug: 'easyapply_filter_options_1' },
      { value: 'No', slug: 'easyapply_filter_options_2' },
    ],
    id: 'isEasyApply',
    slug: 'jobtrack_easyapply_button',
  },
  {
    name: 'Remote',
    value: [
      { value: 'Remote', slug: 'remote_filter_options_1' },
      { value: 'On Site', slug: 'remote_filter_options_2' },
    ],
    id: 'remote',
    slug: 'jobtrack_remote_filter_text',
  },
  {
    name: 'Industries',
    value: [
      { value: 'public administration', slug: 'job_tracking.search.industries.public_administration' },
      { value: 'corporate services', slug: 'job_tracking.search.industries.corporate_services' },
      { value: 'tech', slug: 'job_tracking.search.industries.tech' },
      { value: 'banking / insurance / finance', slug: 'job_tracking.search.industries.banking_insurance_finance' },
      { value: 'culture / media / entertainment', slug: 'job_tracking.search.industries.culture_media_entertainment' },
      { value: 'distribution', slug: 'job_tracking.search.industries.distribution' },
      { value: 'food and beverage', slug: 'job_tracking.search.industries.food_and_beverage' },
      { value: 'hotel / tourism / leisure', slug: 'job_tracking.search.industries.hotel_tourism_leisure' },
      { value: 'legal / law', slug: 'job_tracking.search.industries.legal_law' },
      { value: 'mobility / transport', slug: 'job_tracking.search.industries.mobility_transport' },
      {
        value: 'fashion / luxury / beauty / lifestyle',
        slug: 'job_tracking.search.industries.fashion_luxury_beauty_lifestyle',
      },
      {
        value: 'advertising / marketing / agency',
        slug: 'job_tracking.search.industries.advertising_marketing_agency',
      },
      { value: 'health / social / environment', slug: 'job_tracking.search.industries.health_social_environment' },
      { value: 'architecture', slug: 'job_tracking.search.industries.architecture' },
      { value: 'nonprofit / association', slug: 'job_tracking.search.industries.nonprofit_association' },
      { value: 'real estate', slug: 'job_tracking.search.industries.real_estate' },
      { value: 'industry', slug: 'job_tracking.search.industries.industry' },
      { value: 'engineering', slug: 'job_tracking.search.industries.engineering' },
      { value: 'consulting / audit', slug: 'job_tracking.search.industries.consulting_audit' },
    ],
    id: 'industries',
    slug: '',
    apiProvider: ['search-v5'],
  },
  {
    name: 'Job Type',
    value: [
      { value: 'contractor', name: 'Contractor', slug: 'contactor' },
      { value: 'fulltime', name: 'Full Time', slug: 'full_time' },
      { value: 'parttime', name: 'Part Time', slug: 'part_time' },
      { value: 'intern', name: 'Intern', slug: 'intern' },
    ],
    id: 'jobType',
    slug: 'jobtrack_jobtype_filter_text',
  },
  {
    name: 'Job Provider',
    value: [
      { value: 'linkedin', name: 'linkedin' },
      { value: 'indeed', name: 'indeed' },
      { value: 'glassdoor', name: 'glassdoor' },
      { value: 'naukri', name: 'naukri' },
      { value: 'jooble', name: 'jooble' },
    ],
    id: 'allowedJobProviders',
    checkBox: true,
    slug: 'jobtrack_provider',
  },
  {
    name: 'Languages',
    value: [
      { value: 'French', name: 'fr', slug: 'language.french' },
      { value: 'English', name: 'en', slug: 'language.english' },
      { value: 'Spanish', name: 'es', slug: 'language.spanish' },
    ],
    id: 'language',
    slug: 'languages',
  },
];

export const DEFAULT_SEARCH_SUGGESTION = (t) => {
  return [
    t('jobtrack_default_options_1'),
    t('jobtrack_default_options_2'),
    t('jobtrack_default_options_3'),
    t('jobtrack_default_options_4'),
    t('jobtrack_default_options_5'),
    t('jobtrack_default_options_6'),
    t('jobtrack_default_options_7'),
    t('jobtrack_default_options_8'),
    t('jobtrack_default_options_9'),
    t('jobtrack_default_options_10'),
  ];
};

export const SALARY_TAG_SUFFIX = () => {
  const timeZone = moment.tz.guess(true);
  const tz = ct.getTimezone(timeZone);
  if (tz.country === 'FR') {
    return '€ et +/mois';
  }
  return '$ and +/month';
};

export const COUNTRY_LIST = [
  { name: 'Afghanistan', countryCode: 'AF' },
  { name: 'Åland Islands', countryCode: 'AX' },
  { name: 'Albania', countryCode: 'AL' },
  { name: 'Algeria', countryCode: 'DZ' },
  { name: 'American Samoa', countryCode: 'AS' },
  { name: 'Andorra', countryCode: 'AD' },
  { name: 'Angola', countryCode: 'AO' },
  { name: 'Anguilla', countryCode: 'AI' },
  { name: 'Antarctica', countryCode: 'AQ' },
  { name: 'Antigua and Barbuda', countryCode: 'AG' },
  { name: 'Argentina', countryCode: 'AR' },
  { name: 'Armenia', countryCode: 'AM' },
  { name: 'Aruba', countryCode: 'AW' },
  { name: 'Australia', countryCode: 'AU' },
  { name: 'Austria', countryCode: 'AT' },
  { name: 'Azerbaijan', countryCode: 'AZ' },
  { name: 'Bahamas', countryCode: 'BS' },
  { name: 'Bahrain', countryCode: 'BH' },
  { name: 'Bangladesh', countryCode: 'BD' },
  { name: 'Barbados', countryCode: 'BB' },
  { name: 'Belarus', countryCode: 'BY' },
  { name: 'Belgium', countryCode: 'BE' },
  { name: 'Belize', countryCode: 'BZ' },
  { name: 'Benin', countryCode: 'BJ' },
  { name: 'Bermuda', countryCode: 'BM' },
  { name: 'Bhutan', countryCode: 'BT' },
  { name: 'Bolivia (Plurinational State of)', countryCode: 'BO' },
  { name: 'Bonaire, Sint Eustatius and Saba', countryCode: 'BQ' },
  { name: 'Bosnia and Herzegovina', countryCode: 'BA' },
  { name: 'Botswana', countryCode: 'BW' },
  { name: 'Bouvet Island', countryCode: 'BV' },
  { name: 'Brazil', countryCode: 'BR' },
  { name: 'British Indian Ocean Territory', countryCode: 'IO' },
  { name: 'Brunei Darussalam', countryCode: 'BN' },
  { name: 'Bulgaria', countryCode: 'BG' },
  { name: 'Burkina Faso', countryCode: 'BF' },
  { name: 'Burundi', countryCode: 'BI' },
  { name: 'Cabo Verde', countryCode: 'CV' },
  { name: 'Cambodia', countryCode: 'KH' },
  { name: 'Cameroon', countryCode: 'CM' },
  { name: 'Canada', countryCode: 'CA' },
  { name: 'Cayman Islands', countryCode: 'KY' },
  { name: 'Central African Republic', countryCode: 'CF' },
  { name: 'Chad', countryCode: 'TD' },
  { name: 'Chile', countryCode: 'CL' },
  { name: 'China', countryCode: 'CN' },
  { name: 'Christmas Island', countryCode: 'CX' },
  { name: 'Cocos (Keeling) Islands', countryCode: 'CC' },
  { name: 'Colombia', countryCode: 'CO' },
  { name: 'Comoros', countryCode: 'KM' },
  { name: 'Congo', countryCode: 'CG' },
  { name: 'Congo, Democratic Republic of the', countryCode: 'CD' },
  { name: 'Cook Islands', countryCode: 'CK' },
  { name: 'Costa Rica', countryCode: 'CR' },
  { name: "Côte d'Ivoire", countryCode: 'CI' },
  { name: 'Croatia', countryCode: 'HR' },
  { name: 'Cuba', countryCode: 'CU' },
  { name: 'Curaçao', countryCode: 'CW' },
  { name: 'Cyprus', countryCode: 'CY' },
  { name: 'Czechia', countryCode: 'CZ' },
  { name: 'Denmark', countryCode: 'DK' },
  { name: 'Djibouti', countryCode: 'DJ' },
  { name: 'Dominica', countryCode: 'DM' },
  { name: 'Dominican Republic', countryCode: 'DO' },
  { name: 'Ecuador', countryCode: 'EC' },
  { name: 'Egypt', countryCode: 'EG' },
  { name: 'El Salvador', countryCode: 'SV' },
  { name: 'Equatorial Guinea', countryCode: 'GQ' },
  { name: 'Eritrea', countryCode: 'ER' },
  { name: 'Estonia', countryCode: 'EE' },
  { name: 'Eswatini', countryCode: 'SZ' },
  { name: 'Ethiopia', countryCode: 'ET' },
  { name: 'Falkland Islands (Malvinas)', countryCode: 'FK' },
  { name: 'Faroe Islands', countryCode: 'FO' },
  { name: 'Fiji', countryCode: 'FJ' },
  { name: 'Finland', countryCode: 'FI' },
  { name: 'France', countryCode: 'FR' },
  { name: 'French Guiana', countryCode: 'GF' },
  { name: 'French Polynesia', countryCode: 'PF' },
  { name: 'French Southern Territories', countryCode: 'TF' },
  { name: 'Gabon', countryCode: 'GA' },
  { name: 'Gambia', countryCode: 'GM' },
  { name: 'Georgia', countryCode: 'GE' },
  { name: 'Germany', countryCode: 'DE' },
  { name: 'Ghana', countryCode: 'GH' },
  { name: 'Gibraltar', countryCode: 'GI' },
  { name: 'Greece', countryCode: 'GR' },
  { name: 'Greenland', countryCode: 'GL' },
  { name: 'Grenada', countryCode: 'GD' },
  { name: 'Guadeloupe', countryCode: 'GP' },
  { name: 'Guam', countryCode: 'GU' },
  { name: 'Guatemala', countryCode: 'GT' },
  { name: 'Guernsey', countryCode: 'GG' },
  { name: 'Guinea', countryCode: 'GN' },
  { name: 'Guinea-Bissau', countryCode: 'GW' },
  { name: 'Guyana', countryCode: 'GY' },
  { name: 'Haiti', countryCode: 'HT' },
  { name: 'Heard Island and McDonald Islands', countryCode: 'HM' },
  { name: 'Holy See', countryCode: 'VA' },
  { name: 'Honduras', countryCode: 'HN' },
  { name: 'Hong Kong', countryCode: 'HK' },
  { name: 'Hungary', countryCode: 'HU' },
  { name: 'Iceland', countryCode: 'IS' },
  { name: 'India', countryCode: 'IN' },
  { name: 'Indonesia', countryCode: 'ID' },
  { name: 'Iran (Islamic Republic of)', countryCode: 'IR' },
  { name: 'Iraq', countryCode: 'IQ' },
  { name: 'Ireland', countryCode: 'IE' },
  { name: 'Isle of Man', countryCode: 'IM' },
  { name: 'Israel', countryCode: 'IL' },
  { name: 'Italy', countryCode: 'IT' },
  { name: 'Jamaica', countryCode: 'JM' },
  { name: 'Japan', countryCode: 'JP' },
  { name: 'Jersey', countryCode: 'JE' },
  { name: 'Jordan', countryCode: 'JO' },
  { name: 'Kazakhstan', countryCode: 'KZ' },
  { name: 'Kenya', countryCode: 'KE' },
  { name: 'Kiribati', countryCode: 'KI' },
  { name: "Korea (Democratic People's Republic of)", countryCode: 'KP' },
  { name: 'Korea, Republic of', countryCode: 'KR' },
  { name: 'Kuwait', countryCode: 'KW' },
  { name: 'Kyrgyzstan', countryCode: 'KG' },
  { name: "Lao People's Democratic Republic", countryCode: 'LA' },
  { name: 'Latvia', countryCode: 'LV' },
  { name: 'Lebanon', countryCode: 'LB' },
  { name: 'Lesotho', countryCode: 'LS' },
  { name: 'Liberia', countryCode: 'LR' },
  { name: 'Libya', countryCode: 'LY' },
  { name: 'Liechtenstein', countryCode: 'LI' },
  { name: 'Lithuania', countryCode: 'LT' },
  { name: 'Luxembourg', countryCode: 'LU' },
  { name: 'Macao', countryCode: 'MO' },
  { name: 'Madagascar', countryCode: 'MG' },
  { name: 'Malawi', countryCode: 'MW' },
  { name: 'Malaysia', countryCode: 'MY' },
  { name: 'Maldives', countryCode: 'MV' },
  { name: 'Mali', countryCode: 'ML' },
  { name: 'Malta', countryCode: 'MT' },
  { name: 'Marshall Islands', countryCode: 'MH' },
  { name: 'Martinique', countryCode: 'MQ' },
  { name: 'Mauritania', countryCode: 'MR' },
  { name: 'Mauritius', countryCode: 'MU' },
  { name: 'Mayotte', countryCode: 'YT' },
  { name: 'Mexico', countryCode: 'MX' },
  { name: 'Micronesia (Federated States of)', countryCode: 'FM' },
  { name: 'Moldova, Republic of', countryCode: 'MD' },
  { name: 'Monaco', countryCode: 'MC' },
  { name: 'Mongolia', countryCode: 'MN' },
  { name: 'Montenegro', countryCode: 'ME' },
  { name: 'Montserrat', countryCode: 'MS' },
  { name: 'Morocco', countryCode: 'MA' },
  { name: 'Mozambique', countryCode: 'MZ' },
  { name: 'Myanmar', countryCode: 'MM' },
  { name: 'Namibia', countryCode: 'NA' },
  { name: 'Nauru', countryCode: 'NR' },
  { name: 'Nepal', countryCode: 'NP' },
  { name: 'Netherlands', countryCode: 'NL' },
  { name: 'New Caledonia', countryCode: 'NC' },
  { name: 'New Zealand', countryCode: 'NZ' },
  { name: 'Nicaragua', countryCode: 'NI' },
  { name: 'Niger', countryCode: 'NE' },
  { name: 'Nigeria', countryCode: 'NG' },
  { name: 'Niue', countryCode: 'NU' },
  { name: 'Norfolk Island', countryCode: 'NF' },
  { name: 'North Macedonia', countryCode: 'MK' },
  { name: 'Northern Mariana Islands', countryCode: 'MP' },
  { name: 'Norway', countryCode: 'NO' },
  { name: 'Oman', countryCode: 'OM' },
  { name: 'Pakistan', countryCode: 'PK' },
  { name: 'Palau', countryCode: 'PW' },
  { name: 'Palestine, State of', countryCode: 'PS' },
  { name: 'Panama', countryCode: 'PA' },
  { name: 'Papua New Guinea', countryCode: 'PG' },
  { name: 'Paraguay', countryCode: 'PY' },
  { name: 'Peru', countryCode: 'PE' },
  { name: 'Philippines', countryCode: 'PH' },
  { name: 'Pitcairn', countryCode: 'PN' },
  { name: 'Poland', countryCode: 'PL' },
  { name: 'Portugal', countryCode: 'PT' },
  { name: 'Puerto Rico', countryCode: 'PR' },
  { name: 'Qatar', countryCode: 'QA' },
  { name: 'Réunion', countryCode: 'RE' },
  { name: 'Romania', countryCode: 'RO' },
  { name: 'Russian Federation', countryCode: 'RU' },
  { name: 'Rwanda', countryCode: 'RW' },
  { name: 'Saint Barthélemy', countryCode: 'BL' },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', countryCode: 'SH' },
  { name: 'Saint Kitts and Nevis', countryCode: 'KN' },
  { name: 'Saint Lucia', countryCode: 'LC' },
  { name: 'Saint Martin (French part)', countryCode: 'MF' },
  { name: 'Saint Pierre and Miquelon', countryCode: 'PM' },
  { name: 'Saint Vincent and the Grenadines', countryCode: 'VC' },
  { name: 'Samoa', countryCode: 'WS' },
  { name: 'San Marino', countryCode: 'SM' },
  { name: 'Sao Tome and Principe', countryCode: 'ST' },
  { name: 'Saudi Arabia', countryCode: 'SA' },
  { name: 'Senegal', countryCode: 'SN' },
  { name: 'Serbia', countryCode: 'RS' },
  { name: 'Seychelles', countryCode: 'SC' },
  { name: 'Sierra Leone', countryCode: 'SL' },
  { name: 'Singapore', countryCode: 'SG' },
  { name: 'Sint Maarten (Dutch part)', countryCode: 'SX' },
  { name: 'Slovakia', countryCode: 'SK' },
  { name: 'Slovenia', countryCode: 'SI' },
  { name: 'Solomon Islands', countryCode: 'SB' },
  { name: 'Somalia', countryCode: 'SO' },
  { name: 'South Africa', countryCode: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', countryCode: 'GS' },
  { name: 'South Sudan', countryCode: 'SS' },
  { name: 'Spain', countryCode: 'ES' },
  { name: 'Sri Lanka', countryCode: 'LK' },
  { name: 'Sudan', countryCode: 'SD' },
  { name: 'Suriname', countryCode: 'SR' },
  { name: 'Svalbard and Jan Mayen', countryCode: 'SJ' },
  { name: 'Sweden', countryCode: 'SE' },
  { name: 'Switzerland', countryCode: 'CH' },
  { name: 'Syrian Arab Republic', countryCode: 'SY' },
  { name: 'Taiwan, Province of China', countryCode: 'TW' },
  { name: 'Tajikistan', countryCode: 'TJ' },
  { name: 'Tanzania, United Republic of', countryCode: 'TZ' },
  { name: 'Thailand', countryCode: 'TH' },
  { name: 'Timor-Leste', countryCode: 'TL' },
  { name: 'Togo', countryCode: 'TG' },
  { name: 'Tokelau', countryCode: 'TK' },
  { name: 'Tonga', countryCode: 'TO' },
  { name: 'Trinidad and Tobago', countryCode: 'TT' },
  { name: 'Tunisia', countryCode: 'TN' },
  { name: 'Turkey', countryCode: 'TR' },
  { name: 'Turkmenistan', countryCode: 'TM' },
  { name: 'Turks and Caicos Islands', countryCode: 'TC' },
  { name: 'Tuvalu', countryCode: 'TV' },
  { name: 'Uganda', countryCode: 'UG' },
  { name: 'Ukraine', countryCode: 'UA' },
  { name: 'United Arab Emirates', countryCode: 'AE' },
  { name: 'United Kingdom of Great Britain and Northern Ireland', countryCode: 'GB' },
  { name: 'United States of America', countryCode: 'US' },
  { name: 'United States Minor Outlying Islands', countryCode: 'UM' },
  { name: 'Uruguay', countryCode: 'UY' },
  { name: 'Uzbekistan', countryCode: 'UZ' },
  { name: 'Vanuatu', countryCode: 'VU' },
  { name: 'Venezuela (Bolivarian Republic of)', countryCode: 'VE' },
  { name: 'Viet Nam', countryCode: 'VN' },
  { name: 'Virgin Islands (British)', countryCode: 'VG' },
  { name: 'Virgin Islands (U.S.)', countryCode: 'VI' },
  { name: 'Wallis and Futuna', countryCode: 'WF' },
  { name: 'Western Sahara', countryCode: 'EH' },
  { name: 'Yemen', countryCode: 'YE' },
  { name: 'Zambia', countryCode: 'ZM' },
  { name: 'Zimbabwe', countryCode: 'ZW' },
];

export const REPORT_LIST = [
  { id: 'It is offensive, discriminatory', slug: 'jobtrack_report_option_discriminatory_text' },
  { id: 'It seems like a fake job', slug: 'jobtrack_report_option_fake_text' },
  { id: 'It is inaccurate', slug: 'jobtrack_report_option_inaccurate_text' },
  { id: 'Link is broken', slug: 'jobtrack_report_option_broken_link_text' },
  { id: 'Other', slug: 'jobtrack_report_option_other_text' },
];

export const ONLY_STAGING_FILTERS = ['educationLevel', 'skills', 'partner', 'isManualCheck'];

export const HIDE_IN_FRENCH = [''];

export const valueMapping = {
  month: 'Last 14 days',
  week: 'Last 7 days',
  today: 'Last 24 hours',
  '3days': 'Last 3 days',
};

export const languages = [
  {
    label: 'English',
    language: LANGUAGES_CODES.ENGLISH,
    country: 'United States',
    languageLabel: 'English',
    supported: supported(['all']),
  },
  {
    label: 'French',
    language: LANGUAGES_CODES.FRENCH,
    country: 'France',
    languageLabel: 'French',
    supported: (host) => supported(env.NODE_ENV === 'production' ? JOB_TRACKING_HOST : ['all'], host),
  },
  {
    label: 'Turkish',
    language: LANGUAGES_CODES.TURKISH,
    country: 'Turkey',
    languageLabel: 'Türkçe',
    supported: supported(['all']),
  },
  {
    label: 'Spanish',
    language: LANGUAGES_CODES.SPANISH,
    country: 'Mexico',
    languageLabel: 'Español',
    supported: supported(['all']),
  },
  {
    label: 'Portuguese (Brazil)',
    language: LANGUAGES_CODES.BRAZIL,
    country: 'Brazil',
    languageLabel: 'Português',
    supported: (host) => supported(['resumedone', 'buildmycv', 'bestonlineresume'], host),
  },
  {
    label: 'Italian',
    language: LANGUAGES_CODES.ITALIAN,
    country: 'Italy',
    languageLabel: 'Italiano',
    supported: supported(['all']),
  },
  {
    label: 'Indonesian',
    language: LANGUAGES_CODES.INDONESIAN,
    country: 'Indonesia',
    languageLabel: 'Bahasa Indonesia',
    supported: supported(['all']),
  },
  {
    label: 'Arabic',
    language: LANGUAGES_CODES.MODERN_STANDARD_ARAB,
    country: 'United Arab Emirates',
    languageLabel: 'العربية',
    supported: supported(['all']),
  },
  {
    label: 'Hungarian',
    language: LANGUAGES_CODES.HUNGARIAN,
    country: 'Hungary',
    languageLabel: 'Hungarian',
    supported: supported(['all']),
  },
  {
    label: 'German',
    language: LANGUAGES_CODES.GERMAN,
    country: 'Germany',
    languageLabel: 'Germany',
    supported: supported(['all']),
  },
  {
    label: 'Vietnamese',
    language: LANGUAGES_CODES.VIETNAMESE,
    country: 'Vietnam',
    languageLabel: 'Vietnamese',
    supported: supported(['all']),
  },
  {
    label: 'Polish',
    language: LANGUAGES_CODES.POLISH,
    country: 'Poland',
    languageLabel: 'Polish',
    supported: supported(['all']),
  },
  {
    label: 'Danish',
    language: LANGUAGES_CODES.DANISH,
    country: 'Denmark',
    languageLabel: 'Danish',
    supported: supported(['all']),
  },
  {
    label: 'Swedish',
    language: LANGUAGES_CODES.SWEDISH,
    country: 'Sweden',
    languageLabel: 'Swedish',
    supported: supported(['all']),
  },
  {
    label: 'Finnish',
    language: LANGUAGES_CODES.FINNISH,
    country: 'Finland',
    languageLabel: 'Finnish',
    supported: supported(['all']),
  },
  {
    label: 'Norwegian',
    language: LANGUAGES_CODES.NORWEGIAN,
    country: 'Norway',
    languageLabel: 'Norwegian',
    supported: supported(['all']),
  },
  {
    label: 'Dutch',
    language: LANGUAGES_CODES.DUTCH,
    country: 'Netherland',
    languageLabel: 'Nederlands',
    supported: supported(['all']),
  },
  {
    label: 'Georgian',
    language: LANGUAGES_CODES.GEORGIAN,
    country: 'Georgia',
    languageLabel: 'ქართული',
    supported: supported(['all']),
  },
  {
    label: 'Czech',
    language: LANGUAGES_CODES.CZECH,
    country: 'Czech',
    languageLabel: 'čeština',
    supported: supported(['all']),
  },
  {
    label: 'Russian',
    language: LANGUAGES_CODES.RUSSIAN,
    country: 'Russia',
    languageLabel: 'русский',
    supported: supported(['all']),
  },
  {
    label: 'Greek',
    language: LANGUAGES_CODES.GREEK,
    country: 'Greece',
    languageLabel: 'Ελληνικά',
    supported: supported(['all']),
  },
  {
    label: 'Thai',
    language: LANGUAGES_CODES.THAI,
    country: 'Thailand',
    languageLabel: 'ไทย',
    supported: supported(['all']),
  },
  {
    label: 'Latvia',
    language: LANGUAGES_CODES.LATVIAN,
    country: 'Latvia',
    languageLabel: 'Latvija',
    supported: supported(['all']),
  },
  {
    label: 'Portuguese (Portugal)',
    language: LANGUAGES_CODES.PORTUGUESE,
    country: 'Portugal',
    languageLabel: 'Português',
    supported: supported(['all']),
  },
  {
    label: 'Romania',
    language: LANGUAGES_CODES.ROMANIAN,
    country: 'Romania',
    languageLabel: 'Română',
    supported: supported(['all']),
  },
  {
    label: 'Lithuania',
    language: LANGUAGES_CODES.LITHUANIAN,
    country: 'Lithuania',
    languageLabel: 'lietuvių',
    supported: supported(['all']),
  },
  {
    label: 'Israel',
    language: LANGUAGES_CODES.HEBREW,
    country: 'Israel',
    languageLabel: 'עִברִית',
    supported: supported(['all']),
  },
];

export const RESUMES_TRACKING_COLUMN_HEADER = [
  {
    title: 'Email',
    color: '#cccccc',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  {
    title: 'Resume Type',
    color: '#cccccc',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  {
    title: 'Original Resume',
    color: '#9ddcd0',
    infoBubble: 'This is the resume saved by the user at the very beggining. There was no transformation yet',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  {
    title: 'Pre Checkbox Resume',
    color: '#9ddcd0',
    infoBubble: 'This is the resume before the checkboxes bullet points were added',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  {
    title: 'Resume With Checkboxes',
    color: '#9ddcd0',
    infoBubble: 'This is simply the original resume where we added the checkboxes Bullet Points',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  {
    title: 'Final Resume Without Questionnaire after Type modif',
    color: '#9ddcd0',
    infoBubble: 'This is the resume that was modified to fit the requirement of 1 page or Multiple pages',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  {
    title: 'Final ResumeWithout Questionnaire with polished design',
    color: '#9ddcd0',
    infoBubble: 'This is the final resume without questionnaire after agent polished the design',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  {
    title: 'Resized Resume before Questionnaire',
    color: '#cccccc',
    infoBubble:
      'Resumes sometimes have a lot of Bullet points which critical issues with AI. This resume is resized to reduce the number of bullet points or add some reasonable bullet points when missing to match the perfect number of BP before generating questionnaire',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  {
    title: 'Shadow Resume V1',
    color: '#fdd99f',
    infoBubble: 'This is the raw resume generated after Q1',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  {
    title: 'Final Resume V1 (after auto-review)',
    color: '#fdd99f',
    infoBubble: 'this is the final resume V1 after it is auto-reviewed by AI',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  {
    title: 'Exhaustive Resume V2',
    color: '#abd7f9',
    infoBubble: ' This is the raw resume that is generated avec questionnaire 2 (it might be very long)',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  {
    title: 'Final Resume V2 (After Auto-review)',
    color: '#abd7f9',
    infoBubble: 'This is the final resume V2 after is is auto-reviewed by AI',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  // {
  //   title: 'Search Template With Questionnaire before Type modif.',
  //   color: '#abd7f9',
  //   infoBubble: 'This is the final resume V2 that was tweaked to match the searched position perfectly',
  //   titleSlug: '',
  //   infoBubbleSlug: '',
  // },
  {
    title: 'Search Template With Questionnaire after Type modif.',
    color: '#abd7f9',
    infoBubble:
      'This is the search template after is was optimized in size and content to fit the requirement of 1 Page or Multiple pages',
    titleSlug: '',
    infoBubbleSlug: '',
  },
  {
    title: 'Final Resume With Questionnaire with polished design',
    color: '#abd7f9',
    infoBubble: 'This is the final resume with questionnaire after agent polished the design.',
    titleSlug: '',
    infoBubbleSlug: '',
  },
];

//note: All costs are in USD per 1 million tokens.
//These rates are based on information available as of March 17, 2025, and may vary depending on the specific platform, region, or usage tier.
//It is always recommended to check the official pricing pages of Anthropic and OpenAI for the most up-to-date information."
export const MODELS_PRICING_DATA = {
  'claude-3-5-sonnet-20240620': {
    input_token_cost: 3.0,
    output_token_cost: 15.0,
  },
  'claude-3-5-haiku-20241022': {
    input_token_cost: 0.8,
    output_token_cost: 4.0,
  },
  'claude-3-opus-20240229': {
    input_token_cost: 15.0,
    output_token_cost: 75.0,
  },
  'claude-3-sonnet-20240229': {
    input_token_cost: 3.0,
    output_token_cost: 15.0,
  },
  'claude-3-7-sonnet-20250219': {
    input_token_cost: 3.0,
    output_token_cost: 15.0,
  },
  'claude-3-haiku-20240307': {
    input_token_cost: 0.25,
    output_token_cost: 1.25,
  },
  'gpt-4o-2024-05-13': {
    input_token_cost: 2.5,
    output_token_cost: 10.0,
  },
  'gpt-4o': {
    input_token_cost: 2.5,
    output_token_cost: 10.0,
  },
  'gpt-4': {
    input_token_cost: 30.0,
    output_token_cost: 60.0,
  },
  'gpt-4-turbo': {
    input_token_cost: 10.0,
    output_token_cost: 30.0,
  },
  'gpt-3.5-turbo': {
    input_token_cost: 3.0,
    output_token_cost: 6.0,
  },
};
